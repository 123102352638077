import { isPlatformBrowser } from '@angular/common';
import { Inject, inject, Injectable, PLATFORM_ID } from '@angular/core';

import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getStorageObject } from 'src/utils/storage-manager';

export enum GoogleTagEventType {
  Search = 'search',
  Login = 'login'
}

// Origin input
export interface GoogleTagSearchTerm {
  event: GoogleTagEventType.Search;
  searchTerm: string;
}

export interface GoogleTagLogin {
  event: GoogleTagEventType.Login;
  userId: number | undefined;
}

export type GoogleTagEvent = GoogleTagSearchTerm | GoogleTagLogin;

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  private gtmService = inject(GoogleTagManagerService);
  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  start(): void {
    this.gtmService.addGtmToDom();
  }

  pushTagEvent(tag: GoogleTagEvent): void {
    if (isPlatformBrowser(this.platformId)) {
      const consent = getStorageObject('consentMode') === 'granted';
      if (consent) {
        this.gtmService.pushTag(tag);
      }
    }
  }
}
